/* Template Engine using ES6 template literals */
// Reference: https://javascript.plainenglish.io/how-to-build-a-template-engine-using-template-literals-in-javascript-9ace13ae4514
/*
Example:

let myTemplate = html`<span>
  Hello world, my name is ${"user.name"}
</span>`;

const generatedHTML =  myTemplate({
    user:{
      name: "Vipul",
      gender: "M",
      loggedIn: true
    }
  });

=> conditional rendering in template string

****** You have to use htmlE (encoded) function if you want to use conditional rendering ******
${(params) => {
  if (params.yourParamName) {
    return `<div>${params.yourParamName}</div>`;
  }
  return "";
}}
*/

const evalReg = /(\.)|(\[(\d)\])/;
const safeEval = (key, obj, def) => {
  let lastKey;
  let match;
  do {
    if (lastKey) {
      if (match && match[2]) {
        obj = obj[lastKey][match[3]];
      } else {
        obj = obj[lastKey];
      }
    }
    match = evalReg.exec(key);
    if (!match) {
      lastKey = key;
      break;
    } else {
      lastKey = key.substr(0, match.index);
      key = key.slice(!match[3] ? match.index + 1 : match.index + 3);
    }
  } while (match);
  if (lastKey) {
    obj = obj[lastKey];
  }
  return obj;
};

const replaceTags = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  "(": "%28",
  ")": "%29",
};

const encode = (text, encoded) => {
  if (!encoded && window.DOMPurify) {
    return window.DOMPurify.sanitize(text, {
      CUSTOM_ELEMENT_HANDLING: {
        tagNameCheck: /^w-/, // allow all tags starting with "w-"
      },
    });
  }
  return text
    ? text
        .toString()
        .replace(/[&<>\(\)]/g, (tag) => tag)
        // .replace(/[&<>\(\)]/g, (tag) => replaceTags[tag] || tag)
        .trim()
    : "";
};

export const html = (strings, ...keys) => {
  return (model) => {
    let res = "";
    let i = 0;
    for (let s of strings) {
      res += s;
      const k = keys[i++];
      if (k) {
        if (typeof k === "string") res += encode(safeEval(k, model, k));
        else if (typeof k === "function") res += encode(k(model));
        else res += encode(k);
      }
    }
    return res;
  };
};

export const htmlE = (strings, ...keys) => {
  return (model) => {
    let res = "";
    let i = 0;
    for (let s of strings) {
      res += s;
      const k = keys[i++];
      if (k) {
        if (typeof k === "string") res += encode(safeEval(k, model, k), true);
        else if (typeof k === "function") res += encode(k(model), true);
        else res += encode(k, true);
      }
    }
    return res;
  };
};
